
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/brand/List.vue";
import Upsert from "@/views/brand/Upsert.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { BrandActions, BrandGetters } from "@/store/vm/brand/enums";

interface ComponentData {
  brandIdModal: number | boolean;
}
export default defineComponent({
  name: "brand",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
    Upsert,
  },
  data(): ComponentData {
    return {
      brandIdModal: false,
    };
  },
  methods: {
    onUpsert(brandId: number | boolean) {
      console.log("on upsert");
      console.log(brandId);
      this.brandIdModal = brandId;
    },
    onReset() {
      this.brandIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Thương hiệu", ["Hệ thống"]);
      MenuComponent.reinitialization();
    });

    const onDel = (brandId) => {
      const detail = store.getters[BrandGetters.GET_BY_ID](brandId);

      Swal.fire({
        title: "Bạn muốn xóa thương hiệu?",
        text: detail.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(brandId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (brandId) => {
      return store.dispatch(BrandActions.DELETE, brandId).catch((e) => {
        console.log(e);
      });
    };

    return {
      onDel,
    };
  },
});
