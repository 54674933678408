import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted mt-1 fw-bold fs-6" }
const _hoisted_5 = {
  class: "card-toolbar",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "top",
  "data-bs-trigger": "hover",
  title: "Click to add a user"
}
const _hoisted_6 = ["data-bs-target"]
const _hoisted_7 = { class: "svg-icon svg-icon-3" }
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_11 = { class: "fw-bolder text-muted" }
const _hoisted_12 = { class: "min-w-25px" }
const _hoisted_13 = { class: "min-w-150px" }
const _hoisted_14 = { class: "min-w-140px" }
const _hoisted_15 = { class: "min-w-100px text-end" }
const _hoisted_16 = { class: "d-flex align-items-center" }
const _hoisted_17 = { class: "d-flex align-items-center" }
const _hoisted_18 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_19 = { class: "text-dark fw-bolder text-hover-primary fs-6" }
const _hoisted_20 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_21 = { class: "text-end" }
const _hoisted_22 = ["data-bs-target", "onClick"]
const _hoisted_23 = { class: "svg-icon svg-icon-3" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("brand.list")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("common.count")) + ": " + _toDisplayString(_ctx.count), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn btn-sm btn-light-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": `#${_ctx.modalUpsertId}`,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.upsertBrand(false)))
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("common.create")), 1)
        ], 8, _hoisted_6)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("table", _hoisted_10, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_11, [
              _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$t("common.order")), 1),
              _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$t("brand.name")), 1),
              _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t("brand.status")), 1),
              _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t("common.action")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(index + 1), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(item.name), 1),
                      _createElementVNode("span", _hoisted_20, _toDisplayString(item.description), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass([
                      item.status === 'inactive' ? 'text-muted' : 'text-success'
                    , "fw-bold d-block fs-7"])
                  }, _toDisplayString(item.status), 3)
                ]),
                _createElementVNode("td", _hoisted_21, [
                  _createVNode(_component_el_tooltip, {
                    class: "item",
                    effect: "light",
                    content: _ctx.lang.edit,
                    placement: "top"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", {
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                        "data-bs-toggle": "modal",
                        "data-bs-target": `#${_ctx.modalUpsertId}`,
                        onClick: ($event: any) => (_ctx.upsertBrand(item.brand_id))
                      }, [
                        _createElementVNode("span", _hoisted_23, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                        ])
                      ], 8, _hoisted_22)
                    ]),
                    _: 2
                  }, 1032, ["content"]),
                  _createVNode(_component_el_tooltip, {
                    class: "item",
                    effect: "light",
                    content: _ctx.lang.delete,
                    placement: "top"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", {
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                        onClick: ($event: any) => (_ctx.delBrand(item.brand_id))
                      }, [
                        _createElementVNode("span", _hoisted_25, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                        ])
                      ], 8, _hoisted_24)
                    ]),
                    _: 2
                  }, 1032, ["content"])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}